import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Home from './pages/home/Home';
import AboutUs from './pages/aboutUs/AboutUs';
import Topnav from './components/topnav/Topnav';
import Contact from './pages/contact/Contact';
import Faqs from './pages/faq/Faqs';
import Programs from './pages/program/Programs';
import Objectives from './pages/Objectives/Objectives';
import Get_Involvec from './pages/get/Get_Involvec';
import Footer from './components/footer/Footer';


function App() {

  const Layout =()=>{
    return(
      <div className='bgfram'>
        <Topnav/>
        <Outlet/>
        <Footer/>
      </div>
    )
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children:[
        {
          path: "/",
          element: <Home/>
        },
        {
          path: "/AboutUs",
          element: <AboutUs/>
        },
        {
          path: "/Contact",
          element: <Contact/>
        },
        {
          path: "/Faqs",
          element: <Faqs/>
        },
        {
          path: "/Programs",
          element: <Programs/>
        },
        {
          path: "/Objectives",
          element: <Objectives/>
        },
        {
          path: "/Get_Involvec",
          element: <Get_Involvec/>
        },
      ]
    },
    
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
