import React from 'react'
import "./leftside.scss"
import maps from "../../images/maps.png"

const LeftSide = () => {
  return (
    <div className='lefts'>
        <div className="comp">
            <div className="slug">Mission Statement</div>
            <hr/>
            We are committed to fostering understanding, cooperation and friendship among 
            the people of different cultures through the shared language of art and culture...
            <hr/><hr/>
        </div>
        <div className="preamble">
            <h2>Objectives</h2>
            To develop and strengthen friendly relations and cooperation between peoples of different countries in
            the field of culture and art.
            <hr/>
        </div>
        <div className="africamap">
            <img src={maps} alt="Map"/>
        </div>
        <div className="preamble">
            <h2>Our Partners</h2>
            We are proud to partner with a number of organizations and 
            individuals that share our mission and vision.
            <hr/>
        </div>
        
    </div>
  )
}

export default LeftSide