import React from 'react'
import LeftSide from '../../components/leftSide/LeftSide'
import "./programs.scss"
import "./mobile.scss"
import Pillip_Slider_03 from "../../images/Pillip_Slider_03.jpg"

const Programs = () => {

  

  return (
    <div className='programs'>
        <div className='bodyfram'>
            <div className='bodyRight'>
              <div className="slidPix">
                <img src={Pillip_Slider_03} alt=""/>
              </div>
                <h1>Our Programs</h1>
                <p>We offer a variety of programs and activities that promote cross-cultural understanding 
                    and appreciation of different cultures. Our programs are designed to foster exchange of ideas, 
                    build mutual respect and understanding, and create opportunities for collaboration between 
                    people of different backgrounds. </p>
                <p>We offer activities such as art and music festivals, film screenings, exhibitions, 
                    workshops, lectures, and other cultural events. Our goal is to provide a platform for 
                    people to share their art and culture with each other in a safe and encouraging 
                    environment.</p>
            </div>
            <LeftSide/>
        </div>
    </div>
  )
}

export default Programs