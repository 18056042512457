import LeftSide from "../../components/leftSide/LeftSide";
import "./about.scss";
import "./mobile.scss";
import Pillip_Slider_01 from "../../images/Pillip_Slider_01.jpg"


const AboutUs = () => {
  return (
    <div className="About">
      <div className='bodyfram'>
            <div className='bodyRight'>
              <div className="slidPix">
                <img src={Pillip_Slider_01} alt=""/>
              </div>
              <h1>About Foundation</h1>
              <p>Welcome to our website! We are a cultural and artistic organization dedicated to 
                developing and strengthening friendly relations and cooperation 
                between people of different countries through the shared language of art and culture.</p>
              <p>We believe art and culture are powerful mediums to bridge the gap between different 
                nations and bring people closer together. mission is to foster understanding, cooperation 
                and friendship among the people of different cultures. </p>
              <p>We offer a range of programs and activities that promote cross-cultural understanding and 
                appreciation of different cultures. Our programs are designed to foster exchange of ideas, 
                build mutual respect and understanding, and create opportunities for collaboration between 
                people of different backgrounds. </p>
              <p>We invite you to explore our website and learn more about our organization, our mission, and our programs. 
                We look forward to having you join us in promoting international friendship and 
                understanding through the power of art and culture.</p>
              <div className="mission">
                <h1>Mission Statement</h1>
                <p>We are committed to fostering understanding, cooperation and friendship among the people 
                  of different cultures through the shared language of art and culture. We strive to create a 
                  platform for people to share their art and culture with each other in a safe and encouraging 
                  environment.</p>
              </div>
              
              <h1>Our Partners</h1>
              <p>We are proud to partner with a number of organizations and individuals that share our 
                mission and vision. Our partners include local and international cultural organizations, 
                educational institutions, businesses, and individuals. </p>
              <p>We strive to work together to promote cross-cultural understanding and appreciation of 
                different cultures. With the help of our partners, we are able to reach more people and make a 
                greater impact on our participants.</p>
              <h1>Our Impact</h1>
              <p>We strive to create meaningful connections between people of different cultures 
                through art and culture. We have been able to host a wide variety of programs and 
                events that have had a positive impact on our participants. </p>
              <p>We have witnessed increased understanding and appreciation of different cultures 
                among our participants. We have also seen enhanced collaboration and friendship 
                between people of different backgrounds.</p>
              <p>We are proud to have had a positive impact on our participants and to have provided a 
                platform for people to come together and exchange their art and culture in a safe and 
                encouraging environment.</p>

            </div>
            <LeftSide/>
      </div>
    </div>
  )
}

export default AboutUs