import React, {useState} from "react"
import { Link } from "react-router-dom"
import "./navbar.scss"
import "./mobile.scss"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from "../../images/logo.png";

const Navbar = () => {

  const[isMobile, setIsMobile] = useState(false);

  return (
    <div className='Navbar'>
        <div className="logo">
          <div className="logoLift"><img src={logo} alt="PADOF"/></div>
          <div className="logoName">
            <div className="Lname">Phillip Adeniyi</div>
            <div className="Lname">Opadeyi Foundation</div>
          </div>
        </div>
        <div className="menuBar">
          <div className={ isMobile ? "nav-link-mobile":"nav-link"} onClick={()=> setIsMobile(false)}>
            <div className="navpage"><Link to="/" className="links">Home</Link></div>
            <div className="navpage"><Link to="/AboutUs" className="links">About Us</Link></div>
            <div className="navpage"><Link to="/Programs" className="links">Our Programs</Link></div>
            <div className="navpage"><Link to="/" className="links">Events</Link></div>
            <div className="navpage"><Link to="/Faqs" className="links">FAQs</Link></div>
            <div className="navpage"><Link to="/Contact" className="links">Contact Us</Link></div>
          </div>
          <button className="mobile-menu-icon" onClick={()=> setIsMobile(!isMobile)}>
            { isMobile ? <CloseIcon/> : <MenuIcon/> }
          </button>
        </div>
        
    </div>
  )
}

export default Navbar