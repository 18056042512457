import React from 'react'
import "./get.scss"
import "./mobile.scss"
import LeftSide from "../../components/leftSide/LeftSide";
import Pillip_Slider_01 from "../../images/Pillip_Slider_01.jpg"

const Get_Involvec = () => {
  return (
    <div className='Get'>
        <div className='bodyfram'>
            <div className='bodyRight'>
              <div className="slidPix">
                <img src={Pillip_Slider_01} alt=""/>
              </div>
              <h1>Filling the form</h1>
              <hr/>
              <div className="formC">
                    <div className="txt">
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='First Name' />
                        </div>
                      </div>
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='Last Name' />
                        </div>
                      </div>
                    </div>
                    <div className="txt">
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='Email Address' />
                        </div>
                      </div>
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='Contact Number' />
                        </div>
                      </div>
                    </div>
                    <div className="txt">
                      <div className="txtRight">
                        <input type="text" placeholder='Position' />
                      </div>
                    </div>
                    <div className="txt">
                      <div className="txtRight">
                        <input type="text" placeholder='Contact Address' />
                      </div>
                    </div>
                    <div className="txt">
                      <div className="txtRight">
                        <textarea placeholder='Message...'></textarea>
                      </div>
                    </div>
                    <button>Submit Application</button>
                  </div>
            </div>
            <LeftSide/>
        </div>
    </div>
  )
}

export default Get_Involvec