import React from 'react'
import "./faqs.scss"
import "./mobile.scss"
import LeftSide from '../../components/leftSide/LeftSide'
import Pillip_Slider_04 from "../../images/Pillip_Slider_04.jpg"

const Faqs = () => {
  return (
    <div className='faqs'>
        <div className='bodyfram'>
            <div className='bodyRight'>
              <div className="slidPix">
                  <img src={Pillip_Slider_04} alt=""/>
                </div>
                <h1>What is the goal of your organization?</h1>
                <hr/>
                <p>Our goal is to foster understanding, cooperation and friendship among 
                the people of different cultures through the shared language of art and culture. </p>
                <hr/>
                <h1>What type of events and programs do you offer?</h1>
                <hr/>
                <p>We offer a variety of activities such as art and music festivals, film screenings, exhibitions, workshops, 
                lectures, and other cultural events. </p>
                <hr/>
                <h1>Who are your partners?</h1>
                <hr/>
                <p>Our partners include local and international cultural organizations, educational institutions, 
                businesses, and individuals.</p>
                <hr/>
            </div>
            <LeftSide/>
        </div>
    </div>
  )
}

export default Faqs