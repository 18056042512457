import LeftSide from "../../components/leftSide/LeftSide"
import "./home.scss"
import HeroSlider, {Slide} from 'hero-slider'
import Pillip_Slider_01 from "../../images/Pillip_Slider_01.jpg"
import Pillip_Slider_02 from "../../images/Pillip_Slider_02.jpg"
import Pillip_Slider_03 from "../../images/Pillip_Slider_03.jpg"
import Pillip_Slider_04 from "../../images/Pillip_Slider_04.jpg"
import Pillip_Slider_05 from "../../images/Pillip_Slider_05.jpg"
import Pillip_Slider_06 from "../../images/Pillip_Slider_06.jpg"
import Pillip_Slider_07 from "../../images/Pillip_Slider_07.jpg"
import Pillip_Slider_08 from "../../images/Pillip_Slider_08.jpg"
import Pillip_Slider_09 from "../../images/Pillip_Slider_09.jpg"


const Home = () => {
  return (
    <div className="Home">
        <div className="bodyfram">
          <LeftSide/>
          <div className="bodyRight">
            
            <div className="sliderImg">
              <HeroSlider
                slidingAnimation="left_to_right"
                orientation="horizontal"
                height="400px"
                autoplay
                initialSlide={1}
                onBeforeChange={(previousSlide, nextSlide) => console.log("onBeforeChange", previousSlide, nextSlide)}
                onChange={nextSlide => console.log("onChange", nextSlide)}
                onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}

                style={{backgroundColor: "rgba(0, 0, 0, 0.33)"}}
                settings={{
                  slidingDuration: 50,
                  slidingDelay: 10,
                  shouldAutoplay: true,
                  shouldDisplayButtons: true,
                  autoplayDuration: 50,

                  
                }}
              
              >
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_01
                  }}
                />
                <Slide
                  background={{
                    backgroundImageSrc: Pillip_Slider_02
                    
                  }}
                />
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_03,
                  }}
                />
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_04,
                  }}
                />
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_05,
                  }}
                />
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_06,
                  }}
                />
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_07,
                  }}
                />
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_08,
                  }}
                />
                <Slide
                  background={{
                    backgroundImageBlendMode: 'luminosity',
                    backgroundImageSrc: Pillip_Slider_09,
                  }}
                />
              </HeroSlider>
            </div>
            <div className="aboutus">
              <h1>About Foundation</h1>
              <p>We, the members of PHILLIP ADENIYI OPADEYI FOUNDATION a non-profit organisation do firmly and solemnly resolve to provide for 
                ourselves a constitution and to be governed by the provisions therein 
                contained.</p>
            </div>
            <div className="explore">
              
            </div>
            <div className="carsbox">
              <div className="comUp project">
                <div className="title">Our Programs</div>
                <div className="detaildTitle">
                  Not Available
                </div>
                <div className="more proS">
                  Not Available
                </div>
              </div>
              <div className="comUp event">
                <div className="title">Coming Up Events</div>
                <div className="detaildTitle">
                Coming Soon
                </div>
                <div className="more evntR">
                  More coming Events
                </div>
              </div>
            </div>
            
          </div>
        </div>
    </div>
  )
}

export default Home