import React from 'react'
import "./objectives.scss"
import "./mobile.scss"
import Pillip_Slider_03 from "../../images/Pillip_Slider_03.jpg"
import LeftSide from '../../components/leftSide/LeftSide'

const Objectives = () => {

    const programs = [
        {
          id: 1,
          title: "title with the images",
          img: Pillip_Slider_03
        },
        {
          id: 2,
          title: "title with the images",
          img: Pillip_Slider_03
        },
        {
          id: 3,
          title: "title with the images",
          img: Pillip_Slider_03
        },
        {
          id: 4,
          title: "title with the images",
          img: Pillip_Slider_03
        },
        {
          id: 5,
          title: "title with the images",
          img: Pillip_Slider_03
        },
        {
          id: 6,
          title: "title with the images",
          img: Pillip_Slider_03
        },
      ]

  return (
    <div className='objectives'>
        <div className='bodyfram'>
            <div className='bodyRight'>
              <div className="slidPix">
                <img src={Pillip_Slider_03} alt=""/>
              </div>
                <h1>Aims and objectives</h1>
                <p>1. To carry out humanitarian services to the society
                </p>
                <p>
                2. To develop and strengthen friendly relations and cooperation between peoples of different countries in the field of culture and art
                </p>
                <p>
                3.	To assist in tour organizations of individuals performers, promotion of the cooperation and mutual relations between artistic unions, public organisations, associations, foundations and other non-governmental organisations</p>
                <p>
                4.	To strengthen the unity of society and civic identity, as well as introduce development of the culture-related tourism</p>
                <p>
                  5.	To introduce citizens to cultural and natural heritage with due regard to provision of economic and socio-cultural progress in nigeria and in various regions of the world
                </p>
                <p>
                  6.	To educate, enrich and share the arts by the way of public shows performances, seminar, displays and exhibits, and to do all such other lawful things as considered necessary for the furtherance of nigerian's culture and arts
                </p>
                
            </div>
            <LeftSide/>
        </div>
    </div>
  )
}

export default Objectives