import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../navBar/Navbar';
import "./topnav.scss";
import "./mobile.scss";


const Topnav = () => {
  return (
    <div className="Topnav">
        <div className="topnavbar">
            <div className="left">
                <div className="nletter">
                    Newsletter | +234 802-985-6483
                </div>
            </div>
            <div className="right">
                <div className="tbtn">
                    <Link to="/Objectives" className='toplink'>Aims & Objectives</Link>
                </div>
                <div className="tbtn">
                    <Link to="/Get_Involvec" className='toplink'>Get Involved</Link>
                </div>
                <div className="tbtn">
                Constitution
                </div>
                <div className="tbtn">
                <Link to="https://paystack.com/pay/donation_padof" className='toplink'>Donate</Link>
                </div>
            </div>
        </div>
        <div className="nav">
            <Navbar/>
        </div>
    </div>
  )
}

export default Topnav