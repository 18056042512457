import React from 'react'
import LeftSide from '../../components/leftSide/LeftSide'
import "./contact.scss"
import "./mobile.scss"

const Contact = () => {
  return (
    <div className='contact'>
        <div className='bodyfram'>
            <LeftSide/>
            <div className='bodyRight'>
                <div className="contactMap">
                <iframe scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=100%&amp;height=300&amp;hl=en&amp;q=393%20borno%20Way,%20Alagomeji-Yaba%20Lagos+(Address)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="300" frameborder="0"></iframe><a href='https://maps-generator.com/'>Maps Generator</a>

                </div>
                <div className="contactForm">
                  <h1>Send us a message</h1>
                  <div className="formC">
                    <div className="txt">
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='First Name' />
                        </div>
                      </div>
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='Last Name' />
                        </div>
                      </div>
                    </div>
                    <div className="txt">
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='Email Address' />
                        </div>
                      </div>
                      <div className="txtC">
                        <div className="txtRight">
                          <input type="text" placeholder='Contact Number' />
                        </div>
                      </div>
                    </div>
                    <div className="txt">
                      <div className="txtRight">
                        <input type="text" placeholder='Subject' />
                      </div>
                    </div>
                    <div className="txt">
                      <div className="txtRight">
                        <textarea placeholder='Message...'></textarea>
                      </div>
                    </div>
                    <button>Send Message</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact