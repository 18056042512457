import React from 'react'
import "./footer.scss"

const Footer = () => {
  return (
    <div className='footer'>
        <div className='triangledown'></div>
        <div className="subscrib">
            <div className="txtC">
            <input type="text" placeholder='Enter Your Email For Update' />
            <button>Subscrib</button>
            </div>
            <div className="footext">
            We, the members of PHILLIP ADENIYI OPADEYI FOUNDATION a non-profit organisation do firmly and solemnly 
            resolve to provide for ourselves a constitution and to be governed 
            by the provisions therein contained
            </div>
            <div className="foot">
                Copyright PADOF 2023
            </div>
        </div>
        
    </div>
  )
}

export default Footer